import React, {FC} from 'react'
import {Stack, Text, Box, Center} from '../common'
import {Layout} from '../components/Layout'
import {ZHelmet} from '../components/ZHelmet'

const PrivacyPolicy: FC = props => {
  return (
    <Layout>
      <ZHelmet
        title="Terms and Conditions | best NBFC | Personal Loan | India"
        description="Get complete detail about Terms and Conditions of Zavron Finserv best NBFC or persoanl provider in India just visit page to know more about the Zavron Finserv"
      />
      <Box>
        <Center gutters="var(--s2)" maxWidth="var(--max-width)">
          <Stack space="var(--s2)">
            <Text style={{textAlign: 'left'}} variant="h2">
              Refund Policy
            </Text>
            <Stack space="var(--s0)">
              <Text
                variant="h4"
                style={{
                  fontFamily: 'var(--body-font-family)',
                  lineHeight: '150%',
                }}
                color="light">
                The company is engaged in the business of lending and as such
                there is no refund of money envolved in the normal course of
                business. In case of exceptional circumstances wherein the
                customer has paid EMI in advance the same will be refunded post
                receipt of an application from the customer requesting for
                refund and the request being found satisfactory by the relevant
                officer of the company.
              </Text>
            </Stack>
          </Stack>
        </Center>
      </Box>
    </Layout>
  )
}

export default PrivacyPolicy
